import React, { useEffect, useState } from "react";
import FaqComponent from "react-faq-component";

const data = {
  title: "FAQ",
  rows: [
    {
      title: "How many The Gold Alpha Pass are there?",
      content: `777.`,
    },
    {
      title: "How much does The Gold Alpha Pass cost to mint?",
      content: "Whitelist: 1 sol - Public: 1.5 sol",
    },
    {
      title: "What rights do I have to the artwork?",
      content: `As the sole owner of your The Gold Alpha Pass, you have exclusive rights to do anything and everything you wish to with your artwork.`,
    },
    {
      title: "Why should I be a member of The Gold Alpha?",
      content: (
        <div>
          <p>The Gold Alpha is not just an alpha project.</p>
          <p>
            The Gold Alpha is a P2E + Metaverse + Staking + Tokenomics +
            Breeding project. The Gold Alpha holders will receive airdrops
            without paying any mint costs when they fulfill certain conditions
            in the staking project to be created. And for the staking project,
            our current LP is estimatedly $500,000.
          </p>
          <p>More will be written in the Whitepaper.</p>
        </div>
      ),
    },
  ],
};

const styles = {
  bgColor: "transparent",
  titleTextColor: "rgb(68, 68, 68)",
  rowTitleColor: "rgb(68, 68, 68)",
  // rowContentColor: 'grey',
  arrowColor: "rgb(68, 68, 68)",
  rowContentPaddingTop: "10px",
  rowContentPaddingLeft: "15px",
  rowContentPaddingBottom: "10px",
  rowContentPaddingRight: "10px",
};

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};

export default function Faq() {
  const [rows, setRowsOption] = useState(null);

  useEffect(() => {
    if (rows) {
      rows[0].expand();
    }
  }, [rows]);
  return (
    <div>
      <FaqComponent
        data={data}
        styles={styles}
        config={config}
        getRowOptions={setRowsOption}
      />
    </div>
  );
}
