import "./App.css";
import { useState } from "react";
import { Spiral as Hamburger } from "hamburger-react";
import Faq from "./components/Faq";
import { useSpring, animated } from "react-spring";
import YouTube from "react-youtube";
import clsx from "clsx";

const App = () => {
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const animateDesc = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: {
      duration: 1250,
    },
  });
  const animateTitle = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: {
      duration: 1000,
    },
  });
  const animateGif = useSpring({
    to: { opacity: 1 },

    from: { opacity: 0 },
    config: {
      duration: 1000,
    },
  });

  return (
    <div>
      <nav
      // style={{
      //   background: "url('/img/bg-1.jpeg')",
      //   backgroundSize: "100%",
      // }}
      >
        <div className="nav-container">
          <img className="nav-logo" src="/img/logo.png" alt="" />
          <div className="links desktop">
            <a className="link" href="#teaser">
              Teaser
            </a>
            <a className="link" href="#team">
              Team
            </a>
            <a className="link" href="#faq">
              FAQ
            </a>
          </div>
          <div className="social-icons desktop">
            <a
              className="nav-social twitter"
              href="https://twitter.com/TheGoldAlpha"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="48"
                height="48"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#03A9F4"
                  d="M42,12.429c-1.323,0.586-2.746,0.977-4.247,1.162c1.526-0.906,2.7-2.351,3.251-4.058c-1.428,0.837-3.01,1.452-4.693,1.776C34.967,9.884,33.05,9,30.926,9c-4.08,0-7.387,3.278-7.387,7.32c0,0.572,0.067,1.129,0.193,1.67c-6.138-0.308-11.582-3.226-15.224-7.654c-0.64,1.082-1,2.349-1,3.686c0,2.541,1.301,4.778,3.285,6.096c-1.211-0.037-2.351-0.374-3.349-0.914c0,0.022,0,0.055,0,0.086c0,3.551,2.547,6.508,5.923,7.181c-0.617,0.169-1.269,0.263-1.941,0.263c-0.477,0-0.942-0.054-1.392-0.135c0.94,2.902,3.667,5.023,6.898,5.086c-2.528,1.96-5.712,3.134-9.174,3.134c-0.598,0-1.183-0.034-1.761-0.104C9.268,36.786,13.152,38,17.321,38c13.585,0,21.017-11.156,21.017-20.834c0-0.317-0.01-0.633-0.025-0.945C39.763,15.197,41.013,13.905,42,12.429"
                ></path>
              </svg>
            </a>
            <a
              className="nav-social discord"
              href="https://discord.com/invite/thegoldalpha"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="48"
                height="48"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#8c9eff"
                  d="M40,12c0,0-4.585-3.588-10-4l-0.488,0.976C34.408,10.174,36.654,11.891,39,14c-4.045-2.065-8.039-4-15-4s-10.955,1.935-15,4c2.346-2.109,5.018-4.015,9.488-5.024L18,8c-5.681,0.537-10,4-10,4s-5.121,7.425-6,22c5.162,5.953,13,6,13,6l1.639-2.185C13.857,36.848,10.715,35.121,8,32c3.238,2.45,8.125,5,16,5s12.762-2.55,16-5c-2.715,3.121-5.857,4.848-8.639,5.815L33,40c0,0,7.838-0.047,13-6C45.121,19.425,40,12,40,12z M17.5,30c-1.933,0-3.5-1.791-3.5-4c0-2.209,1.567-4,3.5-4s3.5,1.791,3.5,4C21,28.209,19.433,30,17.5,30z M30.5,30c-1.933,0-3.5-1.791-3.5-4c0-2.209,1.567-4,3.5-4s3.5,1.791,3.5,4C34,28.209,32.433,30,30.5,30z"
                ></path>
              </svg>
            </a>
          </div>
          <div
            className="hamburger-wrapper"
            onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
          >
            <Hamburger
              toggled={isMobileMenuActive}
              toggle={setIsMobileMenuActive}
              color={isMobileMenuActive ? "#000" : "rgb(118 133 193)"}
              // buttonWidth={30}
            />
          </div>
          <div
            className={clsx({
              "mobile-menu": true,
              hide: !isMobileMenuActive,
            })}
          >
            <div className="links mobile">
              <a className="link" href="#teaser">
                Teaser
              </a>
              <a className="link" href="#team">
                Team
              </a>
              <a className="link" href="#faq">
                FAQ
              </a>
            </div>
            <div className="social-icons mobile">
              <a
                className="nav-social twitter"
                href="https://twitter.com/TheGoldAlpha"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#03A9F4"
                    d="M42,12.429c-1.323,0.586-2.746,0.977-4.247,1.162c1.526-0.906,2.7-2.351,3.251-4.058c-1.428,0.837-3.01,1.452-4.693,1.776C34.967,9.884,33.05,9,30.926,9c-4.08,0-7.387,3.278-7.387,7.32c0,0.572,0.067,1.129,0.193,1.67c-6.138-0.308-11.582-3.226-15.224-7.654c-0.64,1.082-1,2.349-1,3.686c0,2.541,1.301,4.778,3.285,6.096c-1.211-0.037-2.351-0.374-3.349-0.914c0,0.022,0,0.055,0,0.086c0,3.551,2.547,6.508,5.923,7.181c-0.617,0.169-1.269,0.263-1.941,0.263c-0.477,0-0.942-0.054-1.392-0.135c0.94,2.902,3.667,5.023,6.898,5.086c-2.528,1.96-5.712,3.134-9.174,3.134c-0.598,0-1.183-0.034-1.761-0.104C9.268,36.786,13.152,38,17.321,38c13.585,0,21.017-11.156,21.017-20.834c0-0.317-0.01-0.633-0.025-0.945C39.763,15.197,41.013,13.905,42,12.429"
                  ></path>
                </svg>
              </a>
              <a
                className="nav-social discord"
                href="https://discord.com/invite/thegoldalpha"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#8c9eff"
                    d="M40,12c0,0-4.585-3.588-10-4l-0.488,0.976C34.408,10.174,36.654,11.891,39,14c-4.045-2.065-8.039-4-15-4s-10.955,1.935-15,4c2.346-2.109,5.018-4.015,9.488-5.024L18,8c-5.681,0.537-10,4-10,4s-5.121,7.425-6,22c5.162,5.953,13,6,13,6l1.639-2.185C13.857,36.848,10.715,35.121,8,32c3.238,2.45,8.125,5,16,5s12.762-2.55,16-5c-2.715,3.121-5.857,4.848-8.639,5.815L33,40c0,0,7.838-0.047,13-6C45.121,19.425,40,12,40,12z M17.5,30c-1.933,0-3.5-1.791-3.5-4c0-2.209,1.567-4,3.5-4s3.5,1.791,3.5,4C21,28.209,19.433,30,17.5,30z M30.5,30c-1.933,0-3.5-1.791-3.5-4c0-2.209,1.567-4,3.5-4s3.5,1.791,3.5,4C34,28.209,32.433,30,30.5,30z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </nav>
      <div className="main-wrapper">
        <header className="card top-card content-wrapper" id="link1">
          <div className="top-left" style={{ padding: "0 24px 0 24px 0" }}>
            <animated.h3
              style={
                { opacity: 0.9, marginBottom: "3px", ...animateTitle } as any
              }
            >
              Welcome To
            </animated.h3>
            <animated.h1
              style={{ paddingBottom: "10px", ...animateTitle } as any}
            >
              The Gold Alpha
            </animated.h1>
            <animated.p style={animateDesc}>
              Not just an Alpha project…
            </animated.p>
          </div>
          <div className="top-right">
            <animated.img style={animateGif} src="/img/gold-alpha-1.gif" />
          </div>
        </header>
        <div className="content-wrapper what-is">
          <h2>What is Gold Alpha ?</h2>
          <p>
            The Gold Alpha is a solid community and the Solana NFT project that
            will serve as a pass for its owners for future opportunities.
          </p>
          <p>
            In the next The Gold Alpha project, holders will be able to take
            advantage of many extras.
          </p>
          <p>
            The Gold Alpha always membersen plans to win and aims to establish a
            reliable environment for its members.
          </p>
        </div>

        <div className="content-wrapper teaser" id="teaser">
          <h2>Teaser</h2>
          <div className="embed">
            <YouTube
              videoId="Hd7NI2zz27M"
              opts={{
                height:
                  window.innerWidth >= 710
                    ? "400"
                    : String(Math.round(window.innerWidth / 1.777)),
                width:
                  window.innerWidth >= 710 ? "710" : String(window.innerWidth),
              }}
            />
          </div>
          <img src="/img/badge.png" className="badge badge-1" />
          <img src="/img/badge.png" className="badge badge-2" />
        </div>
        <div className="content-wrapper team-w" id="team">
          <h2>Team</h2>
          <div className="team">
            <div className="team-card">
              <img className="card-img" src="/img/gold-alpha-3.gif" />
              <div className="info">
                <a href="https://twitter.com/DracoCapitall" target="_blank">
                  <h5> Draco.sol</h5>
                </a>
                <p>
                  I have a 1 year background in the NFT industry. I have worked
                  as a consultant and community manager on many NFT projects. At
                  The Gold Alpha, I will aim to continually profit the community
                  by providing my previous experience and insights into
                  utilities.
                </p>
              </div>
            </div>
            <div className="team-card">
              <img className="card-img" src="/img/gold-alpha-4.gif" />
              <div className="info">
                <a href="https://twitter.com/Smacewow" target="_blank">
                  <h5>Smace</h5>
                </a>
                <p>
                  Before my nearly 1 year nft adventure, I was a cs go trader
                  for many years. I have seen more than zero hills in the nft
                  process. I have served as a consultant and alpha caller for
                  many projects. I will lead the way to plan the best in solana
                  for Gold Alpha and build the strongest community and make the
                  best decisions.
                </p>
              </div>
            </div>
            <div className="team-card">
              <img className="card-img" src="/img/gold-alpha-5.gif" />
              <div className="info">
                <a href="https://twitter.com/GlyphBound" target="_blank">
                  <h5>Kayac Multimedia (Artist)</h5>
                </a>
                <p>
                  Professional art services are just one of the offerings
                  available through the Kayac Multimedia ecosystem. We are proud
                  to collaborate with the Gold Alpha team to bring their
                  artistic vision to life.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper partners-w">
          <h2>Partners</h2>
          <div className="partners">
            <a
              className="partner"
              href="https://twitter.com/metaagee"
              target="_blank"
            >
              <img src="/img/metaage.jpeg" />
              <h4>MetaAge</h4>
            </a>
          </div>
        </div>
        <div className="content-wrapper faq-container" id="faq">
          <Faq />
        </div>
        <footer>
          <div>
            <img className="logo" src="/img/logo-2.gif" />
            <span className="divider"> | </span>
            <div className="social-icons">
              <a
                className="nav-social twitter"
                href="https://twitter.com/TheGoldAlpha"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#03A9F4"
                    d="M42,12.429c-1.323,0.586-2.746,0.977-4.247,1.162c1.526-0.906,2.7-2.351,3.251-4.058c-1.428,0.837-3.01,1.452-4.693,1.776C34.967,9.884,33.05,9,30.926,9c-4.08,0-7.387,3.278-7.387,7.32c0,0.572,0.067,1.129,0.193,1.67c-6.138-0.308-11.582-3.226-15.224-7.654c-0.64,1.082-1,2.349-1,3.686c0,2.541,1.301,4.778,3.285,6.096c-1.211-0.037-2.351-0.374-3.349-0.914c0,0.022,0,0.055,0,0.086c0,3.551,2.547,6.508,5.923,7.181c-0.617,0.169-1.269,0.263-1.941,0.263c-0.477,0-0.942-0.054-1.392-0.135c0.94,2.902,3.667,5.023,6.898,5.086c-2.528,1.96-5.712,3.134-9.174,3.134c-0.598,0-1.183-0.034-1.761-0.104C9.268,36.786,13.152,38,17.321,38c13.585,0,21.017-11.156,21.017-20.834c0-0.317-0.01-0.633-0.025-0.945C39.763,15.197,41.013,13.905,42,12.429"
                  ></path>
                </svg>
              </a>
              <a
                className="nav-social discord"
                href="https://discord.com/invite/thegoldalpha"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#8c9eff"
                    d="M40,12c0,0-4.585-3.588-10-4l-0.488,0.976C34.408,10.174,36.654,11.891,39,14c-4.045-2.065-8.039-4-15-4s-10.955,1.935-15,4c2.346-2.109,5.018-4.015,9.488-5.024L18,8c-5.681,0.537-10,4-10,4s-5.121,7.425-6,22c5.162,5.953,13,6,13,6l1.639-2.185C13.857,36.848,10.715,35.121,8,32c3.238,2.45,8.125,5,16,5s12.762-2.55,16-5c-2.715,3.121-5.857,4.848-8.639,5.815L33,40c0,0,7.838-0.047,13-6C45.121,19.425,40,12,40,12z M17.5,30c-1.933,0-3.5-1.791-3.5-4c0-2.209,1.567-4,3.5-4s3.5,1.791,3.5,4C21,28.209,19.433,30,17.5,30z M30.5,30c-1.933,0-3.5-1.791-3.5-4c0-2.209,1.567-4,3.5-4s3.5,1.791,3.5,4C34,28.209,32.433,30,30.5,30z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
          <div>
            <h4 className="stay-w">Stay with The Gold Alpha.</h4>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default App;
